<template>
  <div>
    <div class="header">

      <div class="head clearfix">
        <div class="logo">
          <router-link to="/">
            <img src="../../assets/images/logo.png" alt="易测电子科技" />
          </router-link>
        </div>
        <div class="nav_m">
          <div class="n_icon">导航栏</div>
          <ul class="nav clearfix">
            <li class="now">
              <router-link to="/home" replace>首页</router-link>
            </li>
            <li>
              <router-link to="/login" replace>控制台</router-link>
            </li>
            <li>
              <a href="product.html">产品页面</a>
            </li>
            <li>
              <a href="friend.html">合作伙伴</a>
              <div class="er">
                <div class="er_m">
                  <i>&nbsp;</i>
                  <ul>
                    <li><a href="">供应商</a></li>
                    <li><a href="">主要客户</a></li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <a href="contact.html">联系我们</a>
              <div class="er">
                <div class="er_m">
                  <i>&nbsp;</i>
                  <ul>
                    <li><a href="">联系我们</a></li>
                    <li><a href="">客户留言</a></li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--幻灯片-->
    <div class="swiper">
      <el-carousel :interval="4000" :height="bannerHeight + 'px'">
        <el-carousel-item v-for="item in banners" :key="item.image">
          <!-- <a :href="item.link"> -->
          <img
            ref="bannerHeight"
            :src="item.image"
            class="bannerImg"
            @load="imgLoad"
          />
          <!-- </a> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--幻灯片-->
    <div class="space_hx">&nbsp;</div>
    <div class="i_m">
      <div class="i_name">
        <img src="../../assets/images/n1.png" width="171" height="59" alt="" />
      </div>
      <ul class="i_ma clearfix">
        <li v-for="(item, index) in productArr" @click="viewDetail(index)" :key="index">
          <!-- <div class="title">
            <img src="../../assets/images/icon3.jpg" alt="" />
            <span>航空电子</span>
          </div> -->
          <div class="a-li">
            <div class="tu">
              <img :src="item.swiperArr[0].url" alt=""/>
            </div>
            <!-- <div class="des">
              宁波易测电子科技有限公司致力于提供高质量天馈线设备测试仪和适配设备,
              拥有强力的研发后台......
            </div> -->
            <div>
              <div class="title">{{ item.title  }}</div>
              <div class="bg-arrow"></div>
            </div>
          </div>
          <!-- <a href="" class="more">+查看详情</a> -->
        </li>
        <!-- <li>
          <div class="title">
            <img src="../../assets/images/icon5.jpg" alt="" />
            <span>工业电子</span>
          </div>
          <div class="tu">
            <a href=""><img src="../../assets/upload/pic3.jpg" alt=""/></a>
          </div>
          <div class="des">
            宁波易测电子科技有限公司专注行业痛点，深究运行机理，开拓创新, ......
          </div>
          <a href="" class="more">+查看详情</a>
        </li>
        <li>
          <div class="title">
            <img src="../../assets/images/icon4.jpg" alt="" />
            <span>数据可视化</span>
          </div>
          <div class="tu">
            <a href=""><img src="../../assets/upload/pic2.jpg" alt=""/></a>
          </div>
          <div class="des">
            宁波易测电子科技有限公司致力于数据赋能客户,打通线下测试和线上数据处理，既保证数据安全
            又促进数据挖掘 ......
          </div>
          <a href="" class="more">+查看详情</a>
        </li> -->
      </ul>
    </div>
    <div class="f_bg">
      <div class="foot">
        <span>版权所有©宁波易测电子科技有限公司</span>
        <span
          ><a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color:#FFFFFF;font-size: 13px;"
            >浙ICP备2021026241号-1</a
          ></span
        >
        <span>地址：浙江省宁波市镇海区骆驼街道弈景大厦寺后胡巷88号210室</span>
        <span>咨询电话：+86-15558242830</span>
        <span>邮箱：easytest@easytest.tech</span>
      </div>
    </div>
  </div>
</template>

<script>
//import { Swiper, SwiperItem } from "../../components/common/swiper";
import product from '../../config/productDetail'
export default {
  name: "Home",
  components: {},
  data() {
    return {
      productArr: product,
      bannerHeight: "",
      banners: [
        {
          image: require("../../assets/upload/banner1.jpg"),
          link: "#"
        },
        {
          image: require("../../assets/upload/banner2.png"),
          link: "#"
        },
        {
          image: require("../../assets/upload/banner3.png"),
          link: "#"
        }
      ]
    };
  },
  methods: {
    viewDetail(index) {
      this.$router.push('/productDetail?id=' + index)
    },
    imgLoad() {
      var that = this;
      that.$nextTick(() => {
        that.bannerHeight = that.$refs.bannerHeight[0].height;
      });
    }
  },
  mounted() {
    var that = this;
    that.imgLoad();
    window.addEventListener("resize", () => {
      that.bannerHeight = that.$refs.bannerHeight[0].height;
      that.imgLoad();
    });
  }
};
</script>

<style scoped>
@import "../../assets/css/thems.css";
@import "../../assets/css/responsive.css";
@import "../../assets/css/base.css";
div, span, i, tabel, tr, td, img, a, html, body, ul, li {
  margin: 0;
}
.swiper {
  clear: both;
  width: 100%;
}
.bannerImg{
  width: 100%;
}
.title{
  width:30%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/* .i_ma li .tu, .i_ma li .tu img{
  width: auto;
  height: auto;
  max-height: 300px;
  max-width: 100%;
} */
</style>

<style scoped lang="scss">
.foot {
  margin: 0 auto;
}
.i_m{
  position: relative;
  min-width: 320px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
.i_ma {
  display: flex;
  flex-wrap: wrap;
  .tu {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 200px;
    margin-right: 20px;
    img {
      max-height: 100% !important;
      max-width: 100%;
      height: auto;
      width: auto;
      transition: all 0.5s;
    }
  }
  li{
    width: 33.33%;
    height: 165px;
    padding-right: 40px;
    font-size: 1.286em;
    // margin-top: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin: 40px 0 0 0;
    // border: 1px solid #d6d6d6;
    color: #333;
    // overflow: hidden;
    cursor: pointer;
    .a-li {
      width: 100%;
      border: 1px solid #d6d6d6;
      display: flex;
      height: 100%;
      box-sizing: border-box;
      // margin-right: 10%;
    }
    .tu {
      width: 180px;
      min-width: 180px;
      overflow: hidden;
      padding: 20px 0 20px 20px;
      box-sizing: border-box;
    }
    .bg-arrow{
      background: url('../../assets/images/productDetail/arr_p.png') no-repeat right center;
      width: 13px;
      height: 5px;
      transition: all .3s;
    }
    &:hover {
      .a-li{
        box-shadow: 2px 2px 10px #ccc;
      }
      img {
        transform: scale(1.1);
      }
      .bg-arrow{
        width: 41px;
        background-position: 0 0;
      }
      &:nth-child(2) {
        .tu img {
           transform: scale(2.8);
        }
      }
      &:nth-child(4) {
        .tu img {
           transform: scale(3);
        }
      }
    }
    &:nth-child(2) {
      .tu {
        overflow: hidden;
        img {
          margin-top: 50px;
          transform: scale(2.3);
        }
      }
    }
    &:nth-child(3n) {
      padding-right: 0;
    }
    &:nth-child(4) {
      .tu {
        overflow: hidden;
        img {
          margin-top: -33px;
          margin-left: 25px;
          transform: scale(2.2);
        }
      }
    }
  }
  .title {
    width: 100%;
    font-size: 16px;
    height: auto;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
  }
}
.f_bg{
  margin-top: 50px;
}
</style>
